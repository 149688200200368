<template>
  <div>
    <div>
      <h1 class="text-3xl font-bold text-black">Admin User Settings</h1>
      <p v-if="adminUser.id" class="text-gray-500 mt-2">
        You are editing the information for your admin account. If you want to edit the main account settings, <router-link :to="{name: 'Settings'}" class="text-pink-500 hover:underline">click here</router-link>.
      </p>
    </div>

    <div v-if="! user.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="mt-12 max-w-3xl w-full">
      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-first-and-last-name :user-prop="adminUser" :admin="true" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-email :user-prop="adminUser" :admin="true" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-phone :user-prop="adminUser" :admin="true" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-password :user-prop="adminUser" :admin="true" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-image :user-prop="adminUser" :admin="true" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-timezone :user-prop="adminUser" :admin="true" />
      </div>

      <!-- <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-notifications :user-prop="adminUser" :admin="true" />
      </div> -->
    </div>
  </div>
</template>

<script>
  import * as SettingsComponents from '@/components/settings'
  export default {
    name: "AdminSettings",

    components: {
      ...SettingsComponents,
    },

    data() {
      return {
        //
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      adminUser() {
        return this.$store.getters['auth/getAdminUser']
      },
    },

    methods: {
      //
    },

    created() {
      this.$store.dispatch("auth/getUser")
    }
  }
</script>

